<template>
  <div class="test">
    <el-header class="header">
      <el-row class="edit-header">
        <el-col :span="5" class="lf-bt">
          <el-button type="warning" @click="back" style="width:100px"
            >Go Back</el-button
          >
        </el-col>
      </el-row>
    </el-header>

    <el-container class="container">
      <el-main class="main">
        <div class="ques">
          <div class="title">
            Set Used Questionnaire
          </div>
        </div>
        <el-form ref="form" label-width="100px">
          <el-row>
            <el-col :span="1">
              <p></p>
            </el-col>
            <el-col :span="4">
              <p>Industry</p>
            </el-col>
            <el-col :span="1">
              <p></p>
            </el-col>
            <el-col :span="5">
              <p>Company Type</p>
            </el-col>
            <el-col :span="2">
              <p></p>
            </el-col>
            <el-col :span="4">
              <p>Using Questionnaire</p>
            </el-col>
            <el-col :span="4">
              <p>Is Available</p>
            </el-col>
          </el-row>
          <el-form-item v-for="(item, index) in values" :key="index">
            <el-row>
              <el-col :span="5">
                <el-input :disabled="true" v-model="item.industry"></el-input>
              </el-col>
              <el-col :span="1">
                <p style="line-height: 13px;margin-left:10px"></p>
              </el-col>
              <el-col :span="4">
                <el-input :disabled="true" v-model="item.company"></el-input>
              </el-col>
              <el-col :span="2">
                <p></p>
              </el-col>
              <el-col :span="5">
                <el-select
                  v-model="item.using_qn"
                  placeholder=""
                  @change="using_qn_change(item)"
                >
                  <el-option
                    v-for="i in find_qn(item)"
                    :key="i.id"
                    :label="i.label"
                    :value="i.id + '. ' + i.name"
                  >
                  </el-option>
                </el-select>
                <p
                  v-if="item.changed"
                  style="margin-left:-30px;margin-top:-7px;margin-bottom:-10px;color:rgba(251, 89, 89, 0.845)"
                >
                  click 'Save' to submit change
                </p>
              </el-col>
              <el-col :span="1">
                <p></p>
              </el-col>
              <el-col :span="1">
                <input
                  :disabled="item.using_qn_id < 1"
                  type="checkbox"
                  v-model="item.is_available"
                  @change="using_qn_change(item)"
                />
              </el-col>
              <el-col :span="1">
                <p></p>
              </el-col>
              <el-col :span="2">
                <el-button plain @click="openQuestionnaire(item)">
                  Open
                </el-button>
              </el-col>
              <el-col :span="1">
                <el-button
                  :disabled="!item.changed"
                  type="primary"
                  plain
                  class="addOptionButton"
                  @click="save(item)"
                  >Save</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import getDataApi from "@/utils/getDataApi";
import saveDataApi from "@/utils/saveDataApi";

export default {
  name: "test",
  mixins: [getDataApi, saveDataApi],
  data() {
    return {
      values: [],
      description: [],
      all_qn: [
        {
          id: "0",
          industry_id: "",
          company_id: "",
          name: "No Questionnaire",
        },
      ],
      qid: this.$route.query.qid,
    };
  },
  components: {},
  methods: {
    getTopLevelDomain() {
      // ai写的获取顶级域名的函数
      try {
        // 获取当前页面的主机名
        const hostname = window.location.hostname;
        // 将主机名按点分割成数组
        const parts = hostname.split(".");
        // 如果数组长度小于2，说明格式不正确
        if (parts.length < 2) {
          return null;
        }
        // 获取最后一个部分作为顶级域名
        const tld = parts[parts.length - 1].toLowerCase();
        // 处理特殊情况，如 .com.cn, .net.cn 等
        const specialTlds = ["cn", "jp", "uk", "br", "tw"];
        if (parts.length > 2 && specialTlds.includes(tld)) {
          const secondLevelDomain = parts[parts.length - 2].toLowerCase();
          // 检查是否是双后缀情况
          if (["com", "net", "org", "gov", "edu"].includes(secondLevelDomain)) {
            return `${secondLevelDomain}.${tld}`;
          }
        }
        return tld;
      } catch (error) {
        console.error("获取顶级域名时发生错误:", error);
        return null;
      }
    },

    openQuestionnaire(item) {
      window.open(
        `${window.location.protocol}//${window.location.host}/step1?qid=${item.using_qn_id}`
      );
    },
    back() {
      this.$router.push("/index");
    },
    save(item) {
      if (this.getTopLevelDomain() == "cn") {
        console.log(`*********** .cn ***********`);
        window.alert("请前往英国站管理后台修改 https://mgmt.oxvalue.ai ");
        return;
      }

      this.$confirm(
        "This will change the questionnaire the chatbot is using, do you want the change to take effect?",
        "提示",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        var post_data = {
          fk_industry_id: item.industry_id,
          fk_company_type_id: item.company_id,
          fk_questionnaire_id: item.using_qn_id,
          is_available: item.is_available.toString(),
        };
        if (post_data.fk_questionnaire_id == 0) {
          post_data.is_available = "false";
        }
        this.$axios({
          method: "post",
          url: `https://api.oxvalue.ai/api/question/v0.1/questionnaire_used/`,
          headers: {
            Authorization: window.localStorage.getItem("ova_admin_token"),
          },
          data: post_data,
        }).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              type: "success",
              message: `Questionnaire used for ${item.industry_name} ${item.company_name} is updated!`,
            });
          }
          item.origin_industry_id = item.industry_id;
          item.changed = false;
        });
      });
    },
    using_qn_change(item) {
      item.using_qn_id = item.using_qn.split(".")[0];
      item.using_qn_name = item.using_qn.slice(
        item.using_qn.indexOf(".") + 2,
        item.using_qn.length
      );
      item.changed = true;
      if (item.using_qn_id == 0) item.is_available = false;
      if (
        item.using_qn_id == item.origin_industry_id &&
        item.is_available == item.origin_is_available
      )
        item.changed = false;
    },
    using_available_change(item) {
      item.changed = true;
      if (item.using_qn_id == 0) item.is_available = false;

      if (
        item.using_qn_id == item.origin_industry_id &&
        item.is_available == item.origin_is_available
      )
        item.changed = false;
    },
    find_qn(item) {
      let temp_list = [
        {
          id: "0",
          industry_id: "",
          company_id: "",
          name: "No Questionnaire",
          label: "No Questionnaire",
        },
      ];
      for (let i in this.all_qn) {
        if (
          item.industry_id == this.all_qn[i].industry_id &&
          item.company_id == this.all_qn[i].company_id
        )
          temp_list.push(this.all_qn[i]);
      }
      return temp_list;
    },
  },
  computed: {},
  created() {
    var industry_list = [];
    var company_list = [];
    this.$axios({
      method: "get",
      url: `https://api.oxvalue.ai/api/question/v0.1/industry_admin/`,
      headers: {
        Authorization: window.localStorage.getItem("ova_admin_token"),
      },
    }).then((res) => {
      if (res.data["code"] == 403) {
        this.$store.dispatch("clear");
        window.localStorage.removeItem("ova_admin_token");
        this.$router.push("/login");
        return;
      }
      industry_list = res.data.data.industry_list;
      this.$axios({
        method: "get",
        url: `https://api.oxvalue.ai/api/question/v0.1/company_type_admin/`,
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
      })
        .then((res2) => {
          company_list = res2.data.data.company_type_list;
        })
        .then(() => {
          for (let i = 0; i < industry_list.length; i++) {
            for (let j = 0; j < company_list.length; j++) {
              this.values.push({
                industry_id: industry_list[i].id,
                industry_name: industry_list[i].name,
                industry: industry_list[i].id + ". " + industry_list[i].name,
                company_id: company_list[j].id,
                company_name: company_list[j].name,
                company: company_list[j].id + ". " + company_list[j].name,
                using_qn_id: "",
                using_qn_name: "",
                using_qn: "",
                changed: false,
                is_available: false,
              });
            }
          }
          this.$axios({
            method: "get",
            url: `https://api.oxvalue.ai/api/question/v0.1/questionnaire_used/`,
            headers: {
              Authorization: window.localStorage.getItem("ova_admin_token"),
            },
          }).then((res3) => {
            if (res3.data.error == "No access") {
              this.$message.error(
                "You have no access to manange using questionnaire."
              );
              this.$router.push("/index");
              return;
            }
            let using_list = res3.data.data.questionnaire_used_list;
            for (let i = 0; i < using_list.length; i++) {
              for (let j = 0; j < this.values.length; j++) {
                if (
                  using_list[i].fk_industry_id == this.values[j].industry_id &&
                  using_list[i].fk_company_type_id == this.values[j].company_id
                ) {
                  if (using_list[i].fk_questionnaire_id != 0) {
                    this.values[j].using_qn_id =
                      using_list[i].fk_questionnaire_id;
                    this.values[j].using_qn_name =
                      using_list[i].questionnaire_name;
                    (this.values[j].origin_industry_id =
                      using_list[i].fk_questionnaire_id),
                      (this.values[j].using_qn =
                        using_list[i].fk_questionnaire_id +
                        ". " +
                        using_list[i].questionnaire_name);
                    this.values[j].is_available = using_list[i].is_available;
                    this.values[j].origin_is_available =
                      using_list[i].is_available;
                    break;
                  } else {
                    this.values[j].using_qn_id = 0;
                    this.values[j].using_qn_name = "No Questionnaire";
                    (this.values[j].origin_industry_id = 0),
                      (this.values[j].using_qn = "No Questionnaire");
                    this.values[j].is_available = false;
                    this.values[j].origin_is_available = false;
                  }
                }
              }
            }
            this.$axios({
              method: "get",
              url: `https://api.oxvalue.ai/api/question/v0.1/questionnaire_admin/`,
              headers: {
                Authorization: window.localStorage.getItem("ova_admin_token"),
              },
            }).then((res4) => {
              let qn_list = res4.data.data.questionnaire_list;
              for (let i = 0; i < qn_list.length; i++) {
                this.all_qn.push({
                  id: qn_list[i].id,
                  industry_id: qn_list[i].fk_industry_id,
                  company_id: qn_list[i].fk_company_type_id,
                  name: qn_list[i].name,
                  label: qn_list[i].id + ". " + qn_list[i].name,
                });
              }
            });
          });
        });
    });
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
.test .linkDialog {
  text-align: left;
}
.test .container {
  padding: 0;
  height: auto;
  min-height: 610px;
}

.test header {
  padding: 0 100px;
}

.test .el-container {
  padding: 0 100px;
}

.test .side {
  border-top: solid 1px #e3e3e3;
  border-right: solid 1px #e3e3e3;
  background: #ffffff;
}

.test .main {
  border-top: solid 1px #e3e3e3;
  background: #ffffff;
}

.test .edit {
  margin-top: 0;
  overflow: auto;
  height: 550px;
}

.test .outline {
  overflow: auto;
  height: 550px;
}

.test .ques-type {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .edit-title {
  color: black;
  padding: 15px 0;
  font-size: 16px;
  border-bottom: solid #e3e3e3 2px;
}

.test .edit-ques {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .type-icon {
  color: #1687fd;
  display: inline-block;
}

.test .type-icon:hover {
  color: #409eff;
  cursor: pointer;
}

.test .el-tabs__nav-scroll {
  text-align: center;
  height: 60px;
  margin: 0 60px;
}

.test .el-tabs__item {
  font-weight: bold;
  padding: 0 20px;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
  display: inline-block;
  list-style: none;
  font-size: 16px;
  color: black;
  position: relative;
}

.test .el-tabs__header {
  margin: 0;
}

.test .el-tree-node__content {
  padding-left: 10px !important;
  height: 40px;
}

/* .test .main {
  max-height: 610px;
} */

.test .ques .title {
  font-size: 28px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 26px;
}

.test .ques .description {
  text-align: left;
  font-size: 16px;
  padding-bottom: 30px;
  color: black;
  line-height: 30px;
}

.test .ques-block {
  padding-bottom: 15px;
  border-top: solid #e3e3e3 1px;
}

.test .ques-block:hover {
  background: #f5f5f5;
  transition: 0.3s;
}

.test .ques-block .must {
  font-weight: normal;
  color: crimson;
}

.test .block-title {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 20px 10px 10px;
  font-weight: bold;
}

.test .block-description {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: #969696;
}

.test .block-refer {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #51c215;
}

.test .block-point {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #e59824;
}

.test .block-choice {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 5px 10px 10px;
}

.test .el-button-group > .el-button:first-child {
  border-radius: 0 0 0 8px;
}

.test .el-button-group > .el-button:last-child {
  border-radius: 0 0 8px 0;
}

.test .block-button .el-button {
  background: #b9b9b9;
  border: transparent;
  padding: 12px 16px;
  font-size: 16px;
}

.test .block-button .el-button:hover {
  background: #989898;
  border: transparent;
}

.test .bt {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.test .block-choice .el-textarea__inner {
  max-height: 100px;
}

.test .dialog {
  text-align: left;
  font-size: large;
}
.test .addOptionButton {
  display: inline-block;
  margin-left: 100px;
}
.test .deleteOptionButton {
  margin-left: 20px;
}

.test .logic-description {
  margin-top: -15px;
  margin-bottom: 30px;
  text-align: left;
  padding-left: 6px;
  font-size: 14px;
  color: #aaaaaa;
}

.test .important {
  color: crimson;
}

.test .logic-title {
  font-size: 16px;
  font-weight: bold;
  padding-left: 12px;
  padding-bottom: 30px;
}

.test .logic-bt {
  text-align: center;
}

.test .addOptionButton {
  display: inline-block;
  margin-left: 20px;
  margin-bottom: 22px;
}

.test .deleteOptionButton {
  margin-left: 20px;
}

.test .settingDialog {
  text-align: center;
}

.test .settingDialog .el-dialog__body {
  padding-left: 30px;
}

.test .logic-form .el-input--suffix .el-input__inner {
  width: 620px;
}

.test .logic-info {
  color: #aaaaaa;
  padding-top: 2px;
  padding-left: 12px;
  padding-bottom: 25px;
}

.test .block-relation {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #1687fd;
}
</style>
